<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nuevo Sector</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <SectorForm></SectorForm>
    </div>
  </v-container>
</template>

<script>
import SectorForm from "../components/SectorForm.vue";

export default {
  name: "SectorAlta",
  components: {
    SectorForm,
  },
  data() {
    return {
      ingresos: null,
      datosDni: {},
    };
  },
};
</script>
