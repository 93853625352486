<template>
  <v-card>
    <v-form v-model="formValidity" ref="form" @submit.prevent="onSubmit">
      <v-container>
        <v-alert dense v-if="error" type="error"
          >Ocurrió un error al enviar la solicitud. Compruebe los datos
          ingresados</v-alert
        >
        <v-row>
          <v-col>
            <v-text-field
              v-model="nombre"
              :counter="100"
              label="Nombre del Sector"
              required
              :rules="nombreRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              v-model="descripcion"
              :counter="300"
              label="Descripcion"
              rows="1"
              auto-grow
              :rules="descripcionRules"
            ></v-textarea>
          </v-col>
        </v-row>
        <br />
        <v-divider> </v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!formValidity" color="primary" type="submit"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn color="error" to="/sectores"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import EmpleadosService from "../services/EmpleadosService";

export default {
  name: "SectorForm",
  components: {},

  data() {
    return {
      nombre: "",
      descripcion: "",
      formValidity: false,
      error: false,
      nombreRules: [
        (v) => !!v || "Debe ingresar un nombre",
        (v) =>
          v.length <= 100 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
      descripcionRules: [
        (v) =>
          v.length <= 300 ||
          "La longuitud del campo nombre debe ser menor o igual a 100 caracteres.",
      ],
    };
  },
  props: {
    sector: {
      type: Object,
      required: false,
    },
  },
  created() {
    if (this.sector) {
      this.nombre = this.sector.nombre;
      this.descripcion = this.sector.descripcion;
    }
  },

  methods: {
    onSubmit() {
      let sectorInstance = {
        nombre: this.nombre,
        descripcion: this.descripcion,
      };
      if (!this.sector) this.sectorNuevo(sectorInstance);
      else this.sectorActualizar(sectorInstance);
    },

    sectorActualizar(sectorInstance) {
      sectorInstance.id = this.sector.id;
      EmpleadosService.putSector(sectorInstance)
        .then((response) => {
          this.$router.push({
            name: "SectorDetalle",
            params: { id: sectorInstance.id },
          });
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },

    sectorNuevo(sectorInstance) {
      EmpleadosService.postSector(sectorInstance)
        .then((response) => {
          this.$router.push({
            name: "Sectores",
          });
        })
        .catch((error) => {
          this.error = error.response.data;
        });
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
